<template>
  <div class="extracurricularAcademicYear">
    <a-modal
      v-model="visibleModal"
      class="mod"
      :width="800"
      :title="
        status.isEdit
          ? `Edit ${dataModal.nama}'s Supervisor`
          : `${dataModal.nama}'s Participant List`
      "
      @cancel="toggleModal"
      centered
    >
      <div v-if="!status.isEdit">
        <a-table
          class="table-scroll"
          :pagination="{ pageSize: 9, hideOnSinglePage: true }"
          :columns="columnParticipant"
          :data-source="participantList"
        >
          <div slot="class" slot-scope="value, record">
            {{ record.tingkat }} - {{ record.simbol }}
          </div>
        </a-table>
      </div>
      <div v-else>
        <a-select
          v-model="supervisorEdit"
          size="large"
          placeholder="Select supervisor"
          style="width: 100%"
        >
          <a-select-option
            v-if="
              dataModal.pembina_ekstrakurikulers[0] &&
              dataModal.pembina_ekstrakurikulers[0].id_pembina
            "
            :value="dataModal.pembina_ekstrakurikulers[0].id_pembina"
            >{{
              dataModal.pembina_ekstrakurikulers[0].guru.nama
            }}</a-select-option
          >
          <a-select-option
            v-for="teacher in availableSupervisorList"
            :key="teacher.id"
            :value="teacher.id"
          >
            {{ teacher.nama }}
          </a-select-option>
        </a-select>
      </div>
      <template slot="footer">
        <a-button
          size="large"
          key="back"
          @click="toggleModal"
          :class="!status.isEdit ? 'd-none' : null"
        >
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :disabled="supervisorEditIsSame"
          :class="!status.isEdit ? 'd-none' : null"
          :loading="loadingActionModal"
          @click="handleEdit"
        >
          Change Now
        </a-button>
      </template>
    </a-modal>
    <div class="mt-4">
      <a-table
        :columns="columns"
        :data-source="dataTable"
        :pagination="pagination"
        :loading="loadingTable"
        bordered
      >
        <div slot="supervisor" slot-scope="value, record">
          {{
            record.pembina_ekstrakurikulers[0]
              ? record.pembina_ekstrakurikulers[0].guru.nama
              : "-"
          }}
          <a-icon
            @click.prevent="toggleModal('supervisor', record)"
            type="edit"
            class="toggleIcon"
          />
        </div>
        <div slot="countPeserta" slot-scope="value, record">
          {{ value }}
          <a-icon
            v-if="value"
            @click.prevent="toggleModal('peserta', record)"
            type="eye"
            class="toggleIcon"
          />
        </div>
        <!-- <div slot="status" slot-scope="value, record">
        <a-tag :color="record.status === 'aktif' ? 'green' : 'red'">{{record.status === 'aktif' ? 'active' : 'inactive'}}</a-tag>
      </div> -->
        <!-- <div slot="action" slot-scope="value, record">
          <a-button
            @click.prevent="toggleModal('edit', record)"
            class="mr-3 text-warning border border-warning"
            size="large"
          >
            <a-icon type="edit" />Edit
          </a-button> -->
        <!-- <a-button
            @click.prevent="handleDelete(record)"
            class="text-danger border border-danger"
            size="large"
          >
            <a-icon type="delete" />Delete
          </a-button> -->
        <!-- </div> -->
      </a-table>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
// const formSchoolYear = () => import('@/components/app/Admin/Master/Form/SchoolYear')

const columns = [
  {
    title: 'Name',
    dataIndex: 'nama',
    key: 'nama',
    align: 'center',
  },
  {
    title: 'Members Total',
    dataIndex: 'countPeserta',
    key: 'countPeserta',
    scopedSlots: { customRender: 'countPeserta' },
    align: 'center',
    width: 150,
  },
  {
    title: 'Supervisor',
    dataIndex: 'pembina_ekstrakurikulers',
    key: 'supervisor',
    scopedSlots: { customRender: 'supervisor' },
    align: 'center',
  },
  // {
  //   title: 'Action',
  //   dataIndex: 'action',
  //   key: 'action',
  //   scopedSlots: { customRender: 'action' },
  //   align: 'center',
  // },
]

const columnParticipant = [
  {
    title: 'Student',
    // width: '100%',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
  },
  {
    title: 'Class',
    // width: '100%',
    dataIndex: 'class',
    scopedSlots: { customRender: 'class' },
    key: 'class',
  },
  // {
  //   title: 'Status',
  //   // width: '100%',
  //   dataIndex: 'Status',
  //   key: 'Status',
  // },
]
export default {
  components: {
  },
  data() {
    return {
      columns,
      visibleModal: false,
      loadingActionModal: false,
      loadingDelete: false,
      loadingTable: false,
      dataTable: [],
      pagination: {},
      status: {
        isEdit: false,
        isCreate: false,
      },
      dataEdit: {
        tahun_awal: null,
        tahun_akhir: null,
        semester: null,
        tanggal_awal: null,
        tanggal_akhir: null,
      },
      idEdit: null,
      idRouteParams: this.$route.params.id,
      columnParticipant,
      participantList: [],
      dataModal: {},
      supervisorEdit: null,
      loadingFetchSupervisor: false,
      availableSupervisorList: [],
    }
  },
  methods: {
    handleChange(payload) {
      const { value, column } = payload
      if (Array.isArray(column)) {
        column.forEach((col, i) => {
          this.dataEdit[col] = value[i]
        })
      } else {
        if (column.split('_').includes('tahun')) {
          const tahun = column.split('_')
          if (isNaN(Number(value))) {
            this.dataEdit.tahun_awal = ''
            this.dataEdit.tahun_akhir = ''
            console.log('tahun')
          } else {
            if (tahun[1] === 'awal') this.dataEdit.tahun_akhir = `${Number(value) + 1}`
            else if (tahun[1] === 'akhir') this.dataEdit.tahun_awal = `${Number(value) + 1}`
            this.dataEdit[column] = value
          }
        } else this.dataEdit[column] = value
      }
    },
    async toggleModal(status, data) {
      try {
        this.visibleModal = !this.visibleModal
        if (status === 'peserta') {
          this.dataModal = data
          this.participantList = data.peserta_ekstrakurikulers.map(peserta => {
            return {
              key: peserta.id,
              id_murid: peserta.kelas_tahun_ajaran.id_murid,
              name: peserta.kelas_tahun_ajaran.murid.nama,
              simbol: peserta.kelas_tahun_ajaran.kela.simbol,
              tingkat: peserta.kelas_tahun_ajaran.kela.level.nama,
            }
          })
        } else if (status === 'supervisor') {
          this.dataModal = data
          this.status.isEdit = true
          this.supervisorEdit = data.pembina_ekstrakurikulers[0]?.id_pembina
          this.loadingFetchSupervisor = true
          await this.fetchTeacherNotSupervisorEkskul()
          this.loadingFetchSupervisor = false
        }

        if (!this.visibleModal) {
          this.dataModal = {}
          this.participantList = []
          this.status.isEdit = false
          this.availableSupervisorList = []
          this.supervisorEdit = null
        }
      } catch (err) {
        console.log(err)
      }
    },
    handleOkModal() {
      const content = `${this.status.isEdit ? 'Are you sure want to edit this study academic year?' : 'Are you sure want to create new study academic year?'}`
      if (this.isValidateForm.isValidate) {
        this.$notification.error({
          message: 'Warning',
          description: this.isValidateForm.message,
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            this.loadingActionModal = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataEdit: this.dataEdit,
            } : {
              dataEdit: this.dataEdit,
            }
            this.$store.dispatch(`master/${this.status.isEdit ? 'EDIT' : 'POST'}_SCHOOL_YEAR`, payload)
              .then(isSuccess => {
                this.loadingActionModal = false
                this.toggleModal()
                this.fetchDataTable()
                if (isSuccess) {
                  const description = this.status.isEdit ? 'Academic Year has been updated' : 'Academic Year has been created'
                  this.$notification.success({
                    message: 'Success',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? 'Academic Year has not been updated' : 'Academic Year has not been created'
                  this.$notification.error({
                    message: 'Failed',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Edit Now' : 'Create Now',
          cancelText: 'Cancel',
        })
      }
    },
    handleDelete(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to delete this study Academic Year?</div>
        ),
        onOk: () => {
          this.loadingTable = true
          this.loadingDelete = true
          this.$store.dispatch('master/DELETE_SCHOOL_YEAR', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataTable()
              this.loadingDelete = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    'Academic Year has been deleted',
                })
              } else {
                this.$notification.error({
                  message: 'Failed',
                  description: 'Academic Year has not been deleted',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDelete = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
    async fetchDataTable(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      // this.loadingTable = true
      // const res = await this.$store.dispatch('master/FETCH_SCHOOL_YEAR', { page: params.page, order: params.order, sortBy: params.sortBy })
      // this.loadingTable = false
      // const pagination = { ...this.pagination }
      const res = await this.$store.dispatch('admin/FETCH_ALL_EXTRACURRICULLAR_ON_ACADEMIC_YEAR', { id: this.idRouteParams, page: params.page, order: params.order, sortBy: params.sortBy })
      this.loadingTable = false
      const pagination = { ...this.pagination }
      pagination.total = res.total
      this.pagination = pagination
      // console.log(res)
      this.dataTable = res.data.map(el => {
        return {
          ...el,
          key: el.id,
          countPeserta: el.peserta_ekstrakurikulers.length,
        }
      })
    },
    async fetchSearch() {
      try {
        this.loadingFetchSupervisor = true
        await this.fetchTeacherNotSupervisorEkskul()
        this.loadingFetchSupervisor = false
      } catch (err) {
        console.log(err)
      }
    },
    async fetchTeacherNotSupervisorEkskul() {
      try {
        const data = await this.$store.dispatch('admin/FETCH_TEACHER_NOT_EXTRACURRICULAR_SUPERVISOR_LIST', { idTahunAjar: this.idRouteParams, idCurrentSupervisor: this.dataModal.pembina_ekstrakurikulers[0]?.id_pembina })
        this.availableSupervisorList = data
        // this.editEkskul.currentSupervisor = this.currentSupervisor.pembina_ekstrakurikulers.length ? this.currentSupervisor.pembina_ekstrakurikulers[0].guru.id : this.editEkskul.currentSupervisor
        return new Promise((resolve) => resolve(true))
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    handleEdit() {
      const content = `Are you sure to change ${this.dataModal.nama}'s supervisor?`
      this.$confirm({
        title: 'Warning',
        content: (
          <div>{content}</div>
        ),
        onOk: () => {
          this.loadingActionModal = true
          this.$store.dispatch('admin/EDIT_CURRENT_SUPERVISOR_OF_EXTRACURRICULAR', { idPembina: this.supervisorEdit, idEkskul: this.dataModal.id, idTahunAjar: this.idRouteParams })
            .then(_ => {
              this.loadingActionModal = false
              // this.loading.updateLoading = false
              this.modalEditSupervisor = false
              this.$notification.success({
                message: 'Success',
                description:
                  `${this.dataModal.nama}'s supervisor has successfully changed.`,
              })
              this.toggleModal()
              this.fetchDataTable()
            })
            .catch(err => {
              console.log(err)
              this.$notification.error({
                message: 'Failed',
                description:
                  `${this.dataModal.nama}'s supervisor can't be changed. Please try again.`,
              })
              this.toggleModal()
              this.fetchDataTable()
            })
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: this.status.isEdit ? 'Edit Now' : 'Create Now',
        cancelText: 'Cancel',
      })
    },
  },
  created() {
    this.fetchDataTable()
  },
  computed: {
    isValidateForm() {
      if (!this.dataEdit.tahun_awal || !this.dataEdit.tahun_akhir || !this.dataEdit.semester || !this.dataEdit.tanggal_awal || !this.dataEdit.tanggal_akhir) {
        return { isValidate: true, message: 'All Fields Are Required!' }
      } else if (String(this.dataEdit.tahun_awal).length !== 4) {
        return { isValidate: true, message: 'Years musts be correct.' }
      }
      return { isValidate: false }
    },
    supervisorEditIsSame() {
      if (this.dataModal.id && this.supervisorEdit) {
        if (this.dataModal.pembina_ekstrakurikulers[0]?.id_pembina === Number(this.supervisorEdit)) {
          return true
        }
      }
      return false
    },
  },
}
</script>

<style lang="scss">
.extracurricularAcademicYear {
  .toggleIcon {
    color: var(--kit-color-primary);
    &:hover {
      transform: scale(1.1);
    }
    transition: 0.1s;
  }
}
</style>
